/// <reference path="index.d.ts" />

import { html, property, css } from "lit-element";
import { ChartCtx } from "./plugin/chartctx";
import { PGDElement } from "./pg.element";
import {chartDataConvertor} from "./chart-data-convertor";

class PGDChartElement extends PGDElement {
  @property({ type: String, attribute: "pg-store" }) pgStore: string;
  @property({ type: String })
  version: string;
  @property({ type: String, attribute: "provider" })
  provider: string;
  @property({ type: String, reflect: true, attribute: "type" }) type: string;
  @property({ type: Array, reflect: true, attribute: "colors" })
  colors: string[];
  @property({ type: Array, reflect: true, attribute: "options" })
  options: string[];
  /**
   * {"text":"Hello","position":"left"}
   * @type {chartAPI.ChartTitle}
   * */
  @property({ type: Object, reflect: true, attribute: "title-options" })
  titleOptions: any;

  @property({ type: Object, reflect: true, attribute: "series" }) series: any;
  @property({
    type: Object,
    hasChanged(newVal, oldVal) {
      if (newVal != oldVal) {
        return true;
      } else {
        return false;
      }
    }
  })
  data: any;
  /**
   * X-Axis option
   */
  @property({ type: Object, reflect: true, attribute: "x-axis" })
  xAxis: chartAPI.Axis;
  /**
   * Y-Axis option
   */
  @property({ type: Object, reflect: true, attribute: "y-axis" })
  yAxis: chartAPI.Axis;
  $: chartAPI.ChartContext;
  _columns: string[];
  _rows: string[];
  /**
   * Rendered instance.
   */
  _chart: chartAPI.ChartComponentAPI;
  /**
   * Provider instance.
   */
  chartProvider: chartAPI.ChartComponentAPI;
  constructor() {
    super();
    window.addEventListener("resize", this._handleResize);
    document.addEventListener("resize", this._handleResize);
    this.type = "bar";
    this.version = "0.1";
  }
  supportTypes() {
    if (this.chartProvider) {
      return this.chartProvider.supportTypes;
    }
    return {};
  }
  getInspector() {
    if (this.chartProvider) return this.chartProvider.getInspector(this.type);
  }
  _handleResize = event => {
    setTimeout(() => {
      this._forceUpdate();
    }, 50);
  };
  _forceUpdate = () => {
    if (this.chartProvider && this.chartProvider.update) {
      this.chartProvider.update({
        columns: this._columns,
        rows: this._rows,
        options: this.options
      });
      this.chartProvider.resize();
    }
  };
  _getAxes = (data, axis) => {
    let scalesData = {
      display: true,
      drawBorder: true,
      drawOnChartArea: true,
      drawTicks: true,
      offsetGridLines: axis === "x",
      tickMarkLength: 10
    };
    let scale = Object.assign({}, scalesData);
    if (data) {
      if (typeof data["label"] === "string") {
        scale["scaleLabel"] = {
          display: !!data["label"],
          labelString: data["label"]
        };
      }
      let cloneXAxis = Object.assign({}, data);
      delete cloneXAxis["label"];
      scale["gridLines"] = {
        borderDash: [
          cloneXAxis.dashWidth || 0,
          !isNaN(cloneXAxis.dashOffset)
            ? cloneXAxis.dashOffset
            : cloneXAxis.dashWidth || 0
        ],
        lineWidth:
          typeof cloneXAxis.lineWidth === "number" ? cloneXAxis.lineWidth : 1,
        color:
          typeof cloneXAxis.color === "string"
            ? cloneXAxis.color
            : "rgba(0, 0, 0, 0.1)"
      };
    }
    scale["ticks"] = {
      beginAtZero: true
    };
    return scale;
  };

  _getConfig() {
    let supportTypes = Object.keys(this.supportTypes());
    if (!supportTypes.includes(this.type)) {
      this.type = supportTypes[0];
    }
    let chartConfig = {
      type: this.type,
      dataTable: {
        columns: this._columns,
        rows: this._rows,
        options: this.options
      },
      options: {
        title: {
          display: !!this.titleOptions.text,
          text: this.titleOptions.text,
          position: this.titleOptions.position
        }
      },
      responsiveAnimationDuration: 0,
      animation: {
        duration: 0 // general animation time
      }
    };

    if (
      !["radar", "pie", "doughnut", "polarArea", "halfDoughnut"].includes(
        this.type
      )
    ) {
      let scale = {};
      let data = Object.assign({}, chartConfig);
      if (this.xAxis) {
        let xAxes = [];
        let scaleX = this._getAxes(this.xAxis, "x");
        xAxes.push(scaleX);
        scale["xAxes"] = xAxes;
      }
      let yAxes = [];
      let scaleY = this._getAxes(this.yAxis, "y");
      yAxes.push(scaleY);
      scale["yAxes"] = yAxes;
      Object.assign(data.options, { scales: scale });
      return data;
    }
    return chartConfig;
  }

  _configUpdate(config) {
    if (config && this.chartProvider) {
      this.chartProvider.updateConfig(config);
    }
  }
  _cleanRender() {
    this.chartProvider.destroy();
    this._renderChart();
  }
  _updateData(next) {
    let sr = typeof next === "object" ? next : JSON.parse(next);
    sr = chartDataConvertor(sr);
    if (sr && sr.category_ instanceof Array) {
      let key = ["category_"];
      let objKeys = Object.keys(sr);
      let srKey = objKeys.filter(item => {
        return item !== "category_";
      });
      let keys = key.concat(srKey);
      this._columns = keys;
      if (
        this.type === "pie" ||
        this.type === "doughnut" ||
        this.type === "polarArea" ||
        this.type === "halfDoughnut"
      ) {
        this._columns = keys.slice(0, 2);
        keys = keys.slice(0, 2);
      }
      this._rows = sr.category_.map((val, index) => {
        return [
          val,
          ...keys
            .filter(key => key !== "category_")
            .map((key, i) => {
              return sr[key][index];
            })
        ];
      });
    }
    this._forceUpdate();
  }
  __handle__() {
    return {
      provider: (prev, next) => {
        this.provider = next;
        this._forceUpdate();
      },
      series: (prev, next) => {
        this._updateData(next);
      },
      type: (prev, type) => {
        this.type = type;
        if (this.series) {
          let fnSr = this.__handle__()["series"];
          if (typeof fnSr === "function") {
            fnSr(prev, this.series);
          }
        }
        if (this.chartProvider) {
          this._configUpdate({ type: type });
          this._cleanRender();
        }
      },
      colors: (prev, colors) => {
        if (colors) {
          setTimeout(() => {
            this._configUpdate({ colors: JSON.parse(colors) });
            this._forceUpdate();
          }, 23);
        }
      },
      "x-axis": (prev, next) => {
        let xAxes = JSON.parse(next) || {};
        this.xAxis = xAxes;
        setTimeout(() => {
          this.chartProvider.axis({ x: xAxes }, this.type);
          this._forceUpdate();
        }, 23);
      },
      "y-axis": (prev, next) => {
        let yAxes = JSON.parse(next) || {};
        this.yAxis = yAxes;
        setTimeout(() => {
          this.chartProvider.axis({ y: yAxes }, this.type);
          this._forceUpdate();
        }, 23);
      },
      "title-options": (prev, title) => {
        let titleObj = JSON.parse(title);
        if (typeof titleObj === "object") {
          this.titleOptions = titleObj;
          if (this.chartProvider) {
            setTimeout(() => {
              this.chartProvider.changeTitle(titleObj);
              this._forceUpdate();
            }, 23);
          }
        }
      },
      options: (prev, next) => {
        let option = JSON.parse(next) || [];
        this.options = option;
        this._forceUpdate();
      },
      "pg-store": (prev, next) => {
        this.pgStore = next;
      }
    };
  }
  attributeChangedCallback(name, prev, next) {
    let fn = this.__handle__()[name];
    if (name === "series") {
      this.series = JSON.parse(next);
    }
    if (typeof fn === "function") {
      fn(prev, next);
    }
  }
  render() {
    return html`
      <style>
        :host {
          display: block;
          margin: auto;
        }
        :host([hidden]) {
          display: none;
        }
        div#Container {
          position: relative;
        }
        canvas#Canvas {
          width: 100%;
          height: 100%;
        }
        .loading-contain{
          width: 100%;
          height: 100%;
          position: absolute;
        }
        .loading {
          width: 100%;
          height: 100%;
          text-align: center;
          position: absolute;
          z-index: 2;
          background-color: #999999;
          opacity: 0.4;
        }
        .loader {
          margin: auto;
          margin-top : 25%;
          font-size: 8px;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          text-indent: -9999em;
          -webkit-animation: load5 1.1s infinite ease;
          animation: load5 1.1s infinite ease;
          -webkit-transform: translateZ(0);
          -ms-transform: translateZ(0);
          transform: translateZ(0);
        }
        @-webkit-keyframes load5 {
          0%,
          100% {
            box-shadow: 0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.5), -1.8em -1.8em 0 0em rgba(0,0,0, 0.7);
          }
          12.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.7), 1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.5);
          }
          25% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.5), 1.8em -1.8em 0 0em rgba(0,0,0, 0.7), 2.5em 0em 0 0em #000000, 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
          }
          37.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.5), 2.5em 0em 0 0em rgba(0,0,0, 0.7), 1.75em 1.75em 0 0em #000000, 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
          }
          50% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.5), 1.75em 1.75em 0 0em rgba(0,0,0, 0.7), 0em 2.5em 0 0em #000000, -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
          }
          62.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.5), 0em 2.5em 0 0em rgba(0,0,0, 0.7), -1.8em 1.8em 0 0em #000000, -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
          }
          75% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.5), -1.8em 1.8em 0 0em rgba(0,0,0, 0.7), -2.6em 0em 0 0em #000000, -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
          }
          87.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.5), -2.6em 0em 0 0em rgba(0,0,0, 0.7), -1.8em -1.8em 0 0em #000000;
          }
        }
        @keyframes load5 {
          0%,
          100% {
            box-shadow: 0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.5), -1.8em -1.8em 0 0em rgba(0,0,0, 0.7);
          }
          12.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.7), 1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.5);
          }
          25% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.5), 1.8em -1.8em 0 0em rgba(0,0,0, 0.7), 2.5em 0em 0 0em #000000, 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
          }
          37.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.5), 2.5em 0em 0 0em rgba(0,0,0, 0.7), 1.75em 1.75em 0 0em #000000, 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
          }
          50% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.5), 1.75em 1.75em 0 0em rgba(0,0,0, 0.7), 0em 2.5em 0 0em #000000, -1.8em 1.8em 0 0em rgba(0,0,0, 0.2), -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
          }
          62.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.5), 0em 2.5em 0 0em rgba(0,0,0, 0.7), -1.8em 1.8em 0 0em #000000, -2.6em 0em 0 0em rgba(0,0,0, 0.2), -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
          }
          75% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.5), -1.8em 1.8em 0 0em rgba(0,0,0, 0.7), -2.6em 0em 0 0em #000000, -1.8em -1.8em 0 0em rgba(0,0,0, 0.2);
          }
          87.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(0,0,0, 0.2), 1.8em -1.8em 0 0em rgba(0,0,0, 0.2), 2.5em 0em 0 0em rgba(0,0,0, 0.2), 1.75em 1.75em 0 0em rgba(0,0,0, 0.2), 0em 2.5em 0 0em rgba(0,0,0, 0.2), -1.8em 1.8em 0 0em rgba(0,0,0, 0.5), -2.6em 0em 0 0em rgba(0,0,0, 0.7), -1.8em -1.8em 0 0em #000000;
          }
        }
        }
      </style>
      <div id="Container">
        ${!this.data && this.pgStore && window["__ENV__"] !== "PGD"
          ? html`
              <div class="loading-contain">
                <div class="loading"></div>
                <div class="loader"></div>
              </div>
            `
          : ""}
        <canvas id="Canvas" on-click="(_checkSelected)"></canvas>
      </div>
    `;
  }
  _renderChart() {
    let config = this._getConfig();
    this._chart = this.chartProvider.render(this.$, config);
  }
  firstUpdated() {
    console.log("version:", this.version);
    this.$ = {
      Container: this.renderRoot.querySelector("#Container"),
      Canvas: this.renderRoot.querySelector("#Canvas")
    };
    if (!this.provider) {
      console.error("Provider can't be empty!");
      let keys = Object.getOwnPropertyNames(ChartCtx.providers);
      this.provider = keys[0];
    }
    let ChartComponent = ChartCtx.get(this.provider);
    if (!ChartComponent) {
      console.error("Chart provider not found!");
      return;
    }
    if (typeof ChartComponent !== "function") {
      console.error("Chart provider is not a function!");
      return;
    }
    //@ts-ignore
    this.chartProvider = new ChartComponent();
    this._renderChart();
  }
  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName == "data" && this.data != oldValue) {
        this._updateData(this.data);
      }
    });
  }
}

customElements.define("pg-chart", PGDChartElement);
