export function chartDataConvertor(data: any) {
  let result = {
    category_: data.category_
  };
  Object.keys(data).forEach((key, idx) => {
    if (key !== "category_") {
      var value = data[key];
      var label = key;
      if (value instanceof Array) {
        result[label] = value;
      } else {
        if (value.data) {
          if (value.label) {
            result[value.label] = value.data;
          } else {
            result[label] = value.data;
          }
        } else {
          if (value.label) {
            result[value.label] = value;
          } else {
            result[label] = value;
          }
        }
      }
    }
  });
  console.log("data convert: ", result);
  return result;
}
